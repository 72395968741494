import React, { Suspense, lazy } from 'react';
import Header from '../Header';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import Footer from '../Footer';
import { welcomeTitle } from '../../utils/constants';
import CenteredLoader from './../../components/CenteredLoader';

import "./styles.css";

const LandingPage = lazy(() => import('../LandingPage'));
const RegistrationForm = lazy(() => import('../RegistrationForm'));
const SubmitPage = lazy(() => import('../SubmitPage'));


const RootContainer = (props) => {
  return (
    <div id="root-container">
      {props.children}
    </div>
  )
}

const Root = () => {
  return (
    <>
      <Header
        title={welcomeTitle}
      />
      <Router>
        <RootContainer>
          <Suspense fallback={<CenteredLoader />}>
            <Switch>
              <Route exact path="/registration" component={RegistrationForm} />
              <Route exact path="/submit" component={SubmitPage} />
              <Route path="/" component={LandingPage} />
            </Switch>
          </Suspense>
        </RootContainer>
      </Router>
      <Footer />
    </>
  )
}

export default Root;