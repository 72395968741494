import React from 'react';
import { copyrightMsg } from "../../utils/constants";

import './styles.css';

const Footer = () => {
  return (
    <div id="footer">
      {copyrightMsg}
    </div>
  )
}

export default Footer;