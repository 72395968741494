import React from 'react';
import LogoBNI from '../../assets/logo_bni_resize.png';
import LogoNUHK from '../../assets/logo_nuhk_resize.png';

import './styles.css';

const Header = (props) => {

  return (
    <div id="headerContainer">
      <div id="logoContainer">
        <div className="logo-image">
          <img src={LogoBNI} className="headerLogo" id="bni-logo" alt="Logo BNI" />
        </div>
        <div id="header-title-container">{props.title}</div>
        <div className="logo-image">
          <img src={LogoNUHK} className="headerLogo" alt="Logo NU Hong Kong"/>
        </div>
      </div>
      <div id="header-title-small">{props.title}</div>
    </div>
  )
}

export default Header;