export const welcomeTitle = "Pendaftaran Kartanu HK";
export const BNIOrange = "#e55300";
export const defaultMajelis = " -- Pilih Majelis Anda -- ";
export const defaultProvinsi = " -- Pilih Provinsi Anda -- ";
export const successTitle = "Sukses";
export const successMsg = "Selamat, data anda sudah berhasil terkirim.";
export const contactVolunteerNoAccountMsg = "Silahkan hubungi Volunteer yang ditunjuk untuk memandu pembukaan rekening melalui link berikut: ";
export const contactVolunteerHasAccountMsg = "Silahkan hubungi volunteer untuk proses pengambilan KartaNU.";
export const volunteerLink = "https://eform.bni.co.id/pembukaanSimpanan/";
export const copyrightMsg = "© Hak Cipta 2019 BNI Remittance Limited Hong Kong";
export const submitMsg = "Lanjut";
export const photoMsg = "Untuk keperluan pencetakan foto pada KartaNU, mohon ambil foto diri setengah badan yang bagus dengan background warna putih.";
const pathUrl = "";
const apiUrl = "/api/v1"
export const formBaseUrl = pathUrl + apiUrl + '/formdata';
export const userDataBaseUrl = pathUrl + apiUrl + '/userdata';
export const failTitle = "Gagal";
export const failMsg = "Pendaftaran gagal, silahkan coba lagi.";
