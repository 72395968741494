import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import './styles.css';

const CenteredLoader = (props) => {
  return (
    <div className="centered-loader">
      <PulseLoader
        color={'#e55300'}
      />
    </div>
  );
}

export default CenteredLoader;